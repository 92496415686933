import React, {Component} from "react";
import {Button, PageHeader} from "react-bootstrap";
import {API} from "aws-amplify";
import "../styles/LicenseList.css";
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import {pathOr} from 'ramda';

export default class LicenseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            licenses: []
        };
    }

    componentDidMount = async () => {
        if (!this.props.isAuthenticated) {
            return;
        }
        try {
            const licenses = await this.licenses();
            this.setState({licenses});
        } catch (e) {
            alert(e);
        }
        this.setState({isLoading: false});
    };

    licenses = () => {
        const customerId = this.props.match.params.customerId;
        return API.get("license", `licenses/${customerId}`, null);
    }

    renderLicenseList = (licenses) => {
        const columns = [{
            Header: 'Customer Name',
            accessor: 'customerName',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.customerName}</a>
        }, {
            Header: 'Number of Users',
            accessor: 'numberOfUsers',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.numberOfUsers}</a>
        }, {
            Header: 'Expiry Date',
            accessor: 'expiryDate',
            Cell: props => <a
                href={`/license/${props.original.licenseId}`}>{new Date(parseInt(props.original.expiryDate, 10)).toDateString()}</a>
        }, {
            Header: 'CVO',
            accessor: 'cvo',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.cvo.toString()}</a>
        }, {
            Header: 'PowerBI',
            accessor: 'powerBI',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.powerBI.toString()}</a>
        }, {
            Header: 'SpreadSheets',
            accessor: 'spreadSheets',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.spreadSheets.toString()}</a>
        }, {
            Header: 'GRC',
            accessor: 'grc',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{pathOr(false, ['original', 'grc'], props).toString()}</a>
        }, {
            Header: 'ProcessManagement',
            accessor: 'processManagement',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{pathOr(false, ['original', 'processManagement'], props).toString()}</a>
        },{
            Header: 'Created By',
            accessor: 'createdBy',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.createdBy}</a>
        }, {
            Header: 'Created At',
            accessor: 'createdAt',
            Cell: props => <a href={`/license/${props.original.licenseId}`}>{props.original.createdAt}</a>
        },]

        return (<ReactTable
                data={licenses}
                resolveData={licenses => licenses.map(row => row)}
                columns={columns}
                sortable={true}
                defaultPageSize={(licenses.length > 5) ? 5 : licenses.length}
                minRows={(licenses.length > 5) ? 5 : licenses.length}
                collapseOnSortingChange={true}
                loading={this.state.isLoading}
                className={'-highlight'}

            />
        )
    }

    /*
    disableOrEnableCreateLicenseButton = () => {
        if (this.state.licenses.length > 0) {
            return false;
        } else {
            return true;
        }
    }
    */
    renderLicenses = () => {
        return (
            <div className="licenses">
                <PageHeader>License List</PageHeader>
                {!this.state.isLoading && this.renderLicenseList(this.state.licenses)}
                <Button href={`/license/customer/new/${this.props.match.params.customerId}`}
                        className={'btn-lg btn-success btn-block'}>
                    <span>Create New License</span></Button>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isAuthenticated ? this.renderLicenses() : this.props.history.push("/login")}
            </div>
        );
    }
}
