import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import Routes from "./Routes";
import "./App.css";
import {Auth} from "aws-amplify";
// React Notification
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }
    async componentDidMount(){
        try {
            await Auth.currentSession();
            this.userHasAuthenticated(true);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
        this.setState({isAuthenticating: false});
    }
    userHasAuthenticated = (authenticated) => {
        this.setState({isAuthenticated: authenticated});
    }
    handleLogout = async (event) => {
        await Auth.signOut();
        this.userHasAuthenticated(false);
        this.props.history.push("/login")
    }
    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated
        };
        return (
            !this.state.isAuthenticating &&
            <div className="App container">
                <Navbar fluid>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <Link to="/">License Generator</Link>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            {this.state.isAuthenticated
                                ? <NavItem onClick={this.handleLogout}>Logout</NavItem> :
                            null
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Routes childProps={childProps}/>
                <NotificationContainer/>
            </div>
        );
    }
}

export default withRouter(App);
