import React, {Component} from "react";
import {Checkbox, ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton"
import "../styles/NewLicense.css"
import {API, Auth} from "aws-amplify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fileDownload from 'js-file-download';
import {NotificationManager} from 'react-notifications';
import SelectSearch from 'react-select-search'
import {pathOr} from 'ramda';

export default class NewLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            isLoading: null,
            applicationName: '',
            customerName: '',
            attachment: '',
            selectedOption: null,
            spreadSheets: false,
            cvo: false,
            powerBI: false,
            grc: false,
            processManagement: false,
            noCustomer: false,
            readOnly: false,
            disableFieldsForSurveyor: false,
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            numberOfUsers: '-1',
            createdBy: '',
            customers: []
        };

        this.getAuthenticatedUser();
        this.getCustomerNameIfPathParametersExist();
    }

    componentDidMount = async () => {
        if (!this.props.isAuthenticated) {
            return;
        }
        try {
            const customers = await this.getCustomers();
            this.setState({customers});
        } catch (e) {
            console.log(e);
        }
        this.setState({isLoading: false});
    };

    validateForm() {
        return this.state.attachment.length > 0;
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleChangeApplicationName = (event) => {
        if (event.target.value === 'Surveyor') {
            this.setState({
                applicationName: event.target.value,
                numberOfUsers: '-1',
                disableFieldsForSurveyor: true,
            });
        } else {
            this.setState({
                applicationName: event.target.value,
                disableFieldsForSurveyor: false
            });
        }
    }

    toggleChangeSpreadSheets = (event) => {
        this.setState({
            spreadSheets: event.target.checked,
        });
    };

    toggleChangeCvo = (event) => {
        this.setState({
            cvo: event.target.checked,
        });
    };

    toggleChangePowerBI = (event) => {
        this.setState({
            powerBI: event.target.checked,
        });
    };

    toggleChangeGRC = (event) => {
        this.setState({
            grc: event.target.checked,
        });
    };

    toggleChangeProcessManagement = (event) => {
        this.setState({
            processManagement: event.target.checked,
        });
    };

    toggleNoCustomer = (event) => {
        this.setState({
            noCustomer: event.target.checked,
            customerId: 'PartnerId',
            customerName: 'Partner',
            readOnly: event.target.checked,
        });

    };

    handleChangeDate = (date) => {
        this.setState({
            startDate: date,
        })
    }

    handleChangeCustomerList = (selectedOption) => {
        this.setState({
            customerId: (selectedOption !== null) ? selectedOption.value : 'PartnerId',
            customerName: (selectedOption !== null) ? selectedOption.name : 'Partner'
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({isLoading: true});
        try {
            await this.createLicense({
                customerId: (this.state.customerId !== '') ? this.state.customerId : 'PartnerId',
                customerName: (this.state.customerName !== '') ? this.state.customerName : 'Partner',
                applicationName: this.state.applicationName,
                attachment: this.state.attachment,
                expiryDate: this.state.startDate.getTime(),
                numberOfUsers: this.state.numberOfUsers,
                spreadSheets: this.state.spreadSheets,
                cvo: this.state.cvo,
                powerBI: this.state.powerBI,
                grc: this.state.grc,
                processManagement: this.state.processManagement,
                createdBy: this.state.createdBy
            });
        } catch (e) {
            console.log(e);
            this.setState({isLoading: false});
        }
    };

    getLicenses = (customerId) => {
        return API.get("license", `licenses/${customerId}`, null);
    }

    getCustomers = () => {
        return API.get("license", `getCustomers`, null);
    }

    createLicense = (license) => {
        return API.post("license", "createLicense", {
            body: license
        }).then(response => {
            const timer = setTimeout(() => {
                const content = response.content;
                const fileName = response.attachment + '.license';
                this.props.history.push("/");
                fileDownload(content, fileName);
                NotificationManager.success('You have created a new License!', 'Successful!', 5000);
            }, 1000);
            return () => clearTimeout(timer);
        }).catch(error => {
            console.log(error)
        });
    };

    getCustomerListValue = (customers) => {
        const sortedCustomerList = customers.sort((a, b) => a.customerName.localeCompare(b.customerName));
        const options = [];
        const customerId = this.props.match.params.customerId;
        if (customerId !== undefined) {
            const filteredCustomer = sortedCustomerList.filter(result => {
                return result.customerId === customerId;
            })
            filteredCustomer.forEach((key) => {
                options.push({name: key.customerName, value: key.customerId})
            });
        } else {
            sortedCustomerList.forEach((key) => {
                options.push({name: key.customerName, value: key.customerId})
            });
        }
        return options
    }

    getCustomerNameIfPathParametersExist = () => {
        const customerId = this.props.match.params.customerId;
        if (customerId !== undefined) {
            this.getLicenses(customerId).then(response => {
                const list = [];
                for (let i = 0; i < response.length; i++) {
                    list.push(response[i])
                }
                const lastSavedIndex = list[0];
                if (this.state.noCustomer !== true && lastSavedIndex !== undefined) {
                    this.setState({
                        numberOfUsers: lastSavedIndex.numberOfUsers,
                        spreadSheets: lastSavedIndex.spreadSheets,
                        cvo: lastSavedIndex.cvo,
                        grc: pathOr(false, ['grc'], lastSavedIndex),
                        processManagement: pathOr(false, ['processManagement'], lastSavedIndex),
                        powerBI: lastSavedIndex.powerBI
                    })
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    getAuthenticatedUser = () => {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.setState({createdBy: user.attributes.email});
        }).catch(err => console.log(err));
    }

    renderCustomerList = (options, readOnly) => {
        if (!readOnly) {
            return (
                <FormGroup controlId="customerName">
                    <ControlLabel>Customer Name</ControlLabel>
                    <SelectSearch
                        name={'customers'}
                        mode={'input'}
                        value={this.state.customerId}
                        options={options}
                        onChange={this.handleChangeCustomerList}
                        placeholder={'Select Customer'}
                    />
                </FormGroup>
            );
        } else {
            return null;
        }

    }

    render() {
        const options = this.getCustomerListValue(this.state.customers);
        return (
            <div className="newLicense">
                <form onSubmit={this.handleSubmit} id={'newLicense'}>
                    <FormGroup controlId="applicationName">
                        <ControlLabel>Application Name</ControlLabel>
                        <FormControl componentClass="select" onChange={this.handleChangeApplicationName}
                                     value={this.state.applicationName}>
                            <option value={"BMP"}>BMP</option>
                            <option value={"Surveyor"}>Surveyor</option>
                        </FormControl>
                    </FormGroup>
                    {this.renderCustomerList(options, this.state.readOnly)}
                    <Checkbox type="checkbox" label="No Customer" checked={this.state.noCustomer}
                              onChange={this.toggleNoCustomer}>No
                        Customer</Checkbox>
                    <FormGroup controlId="attachment">
                        <ControlLabel>File Name</ControlLabel>
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.attachment}
                            componentClass="input">
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="expiryDate">
                        <ControlLabel>Expiry date</ControlLabel>
                        <DatePicker
                            id={'expiryDate'}
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            className={'form-control'}
                            dateFormat={'MMMM d, yyyy'}
                        />
                    </FormGroup>
                    <FormGroup controlId="numberOfUsers">
                        <ControlLabel>Number of Users</ControlLabel>
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.numberOfUsers}
                            componentClass="input"
                            readOnly={this.state.disableFieldsForSurveyor}>
                        </FormControl>
                    </FormGroup>
                    <ControlLabel>Concepts</ControlLabel>
                    <FormGroup controlId="spreadSheets">
                        <Checkbox type="checkbox"
                                  label="SpreadSheets"
                                  checked={this.state.spreadSheets}
                                  onChange={this.toggleChangeSpreadSheets}
                                  disabled={this.state.disableFieldsForSurveyor}>
                            SpreadSheets
                        </Checkbox>
                    </FormGroup>
                    <FormGroup controlId="cvo">
                        <Checkbox type="checkbox"
                                  label="CVO"
                                  checked={this.state.cvo}
                                  onChange={this.toggleChangeCvo}
                                  disabled={this.state.disableFieldsForSurveyor}>
                            CVO
                        </Checkbox>
                    </FormGroup>
                    <FormGroup controlId="powerBI">
                        <Checkbox type="checkbox"
                                  label="PowerBI"
                                  checked={this.state.powerBI}
                                  onChange={this.toggleChangePowerBI}
                                  disabled={this.state.disableFieldsForSurveyor}>
                            PowerBI
                        </Checkbox>
                    </FormGroup>
                    <FormGroup controlId="grc">
                        <Checkbox type="checkbox"
                                  label="GRC"
                                  checked={this.state.grc}
                                  onChange={this.toggleChangeGRC}
                                  disabled={this.state.disableFieldsForSurveyor}>
                            GRC
                        </Checkbox>
                    </FormGroup>
                    <FormGroup controlId="processManagement">
                        <Checkbox type="checkbox"
                                  label="ProcessManagement"
                                  checked={this.state.processManagement}
                                  onChange={this.toggleChangeProcessManagement}
                                  disabled={this.state.disableFieldsForSurveyor}>
                            ProcessManagement
                        </Checkbox>
                    </FormGroup>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type={"submit"}
                        isLoading={this.state.isLoading}
                        text={"Save And Download"}
                        loadingText={"Creating License..."}
                        data-cy={"submit"}
                    />
                </form>
            </div>

        )
    }
}