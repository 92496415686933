import React, {Component} from "react";
import XLSX from 'xlsx';
import {make_cols} from '../utility/MakeColumn';
import SheetJSFT from '../utility/exportTypes';
import {API, Auth} from 'aws-amplify';
import JSZip from 'jszip';
import saveAs from "save-as/src/lib";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderButton from "../components/LoaderButton";

export default class MultipleLicenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            data: [],
            cols: [],
            isLoading: null,
            createdBy: "",
            generatedLicenseData: [],
        }
        this.handleFileSubmit = this.handleFileSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.getAuthenticatedUser();
    }
    validateForm() {
        return this.state.file.size > 0;
    }
    handleFileChange = (e) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files[0]) this.setState({file: files[0]});

    }

    handleFileSubmit() {
        const reader = new FileReader();
        const readAsBinary = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            const targetData = e.target.result;
            const wb = XLSX.read(targetData, {type: readAsBinary ? 'binary' : 'array', bookVBA: true});
            const worksheetName = wb.SheetNames[0];
            const ws = wb.Sheets[worksheetName];
            const parsedData = XLSX.utils.sheet_to_json(ws);
            this.setState({data: parsedData, cols: make_cols(ws['!ref']), isLoading: true}, () => {
                const customers = this.state.data;
                if (customers[0]['accountid'] && customers[0]['name']
                    && customers[0]['plus_nooflicensedusers']
                    && customers[0]['plus_licenseexpirydate'] !== null) {
                    this.createLicenseForMultipleCustomers(customers);
                } else {
                    toast.error('Not a valid file');
                    this.setState({isLoading: false});
                }
            });
        };
        if (readAsBinary) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        }
    }

    createLicenseForMultipleCustomers = (customerList) => {
        this.customers = customerList;
        try {
            this.customers.forEach((value, index) => {
                const customers = value['name'];
                const numberOfUsers = value['plus_nooflicensedusers'];
                this.createLicenseApi({
                    customerId: value['accountid'],
                    customerName: customers,
                    applicationName: 'BMP',
                    attachment: customers + '.license',
                    expiryDate: new Date(value['plus_licenseexpirydate']).getTime(),
                    numberOfUsers: numberOfUsers,
                    spreadSheets: 'false',
                    cvo: 'false',
                    powerBI: 'false',
                    grc: 'false',
                    processManagement: 'false',
                    createdBy: this.state.createdBy
                }).then(response => {
                    const timer = setTimeout(() => {
                        this.state.generatedLicenseData.push({
                            'content': response.content,
                            'fileName': response.attachment
                        });
                        this.downloadMultipleFiles(this.state.generatedLicenseData);
                    }, 1000);
                    return () => clearTimeout(timer);
                }).catch(error => {
                    console.log(error)
                });
            })

        } catch (error) {
            console.log(error)
            this.setState({isLoading: false});
        }
    };

    createLicenseApi = (license) => {
        return API.post("license", "createLicense", {
            body: license
        })
    };

    getAuthenticatedUser = () => {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.setState({createdBy: user.attributes.email});
        }).catch(err => console.log(err));
    };

    downloadMultipleFiles = (licenses) => {
        let zip = new JSZip();
        let count = 0;
        licenses.forEach((data) => {
            zip.file(data.fileName, data.content, {binary: true});
            const timer = setTimeout(() => {
                zip.generateAsync({type: 'blob'}).then((content) => {
                    count = count + 1;
                    if (count === licenses.length) {
                        toast.success("You have generated  " + count + " licenses Successfully!");
                        count = 0;
                        saveAs(content, 'licenses.zip');
                        this.setState({isLoading: false});
                    }
                });
            }, 20000);
            return () => {clearTimeout(timer)};

        });
    };

    render() {
        return (
            <div className={'multipleLicenses'}>
                <label htmlFor="file">Upload File To generate Multiple Licenses</label>
                <br/>
                <input type="file" className="form-control" id="file" accept={SheetJSFT}
                       onChange={this.handleFileChange} data-cy={'file-input'}/>
                <br/>
                <LoaderButton
                    block
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type={"submit"}
                    isLoading={this.state.isLoading}
                    text={"Save And Download"}
                    loadingText={"Creating Multiple Licenses..."}
                    data-cy={"submit"}
                    onClick={this.handleFileSubmit}
                />
                <ToastContainer position={toast.POSITION.TOP_RIGHT}/>

            </div>
        );
    }

}