import React, {Component} from "react";
import XLSX from 'xlsx';
import {make_cols} from '../utility/MakeColumn';
import SheetJSFT from '../utility/exportTypes';
import {API} from 'aws-amplify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderButton from "../components/LoaderButton"
import {toast, ToastContainer} from "react-toastify";

export default class UpdateCustomers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file: {},
            data: [],
            cols: [],
            isLoading: null,
        }
        this.handleFileSubmit = this.handleFileSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    validateForm() {
        return this.state.file.size > 0;
    }

    handleFileChange = (e) => {
        e.preventDefault();
        const files = e.target.files;
        if (files && files[0]) this.setState({file: files[0]});

    }

    handleFileSubmit() {
        const reader = new FileReader();
        const readAsBinary = !!reader.readAsBinaryString;
        reader.onload = (e) => {
            const targetData = e.target.result;
            const wb = XLSX.read(targetData, {type: readAsBinary ? 'binary' : 'array', bookVBA: true});
            const worksheetName = wb.SheetNames[0];
            const ws = wb.Sheets[worksheetName];
            const parsedData = XLSX.utils.sheet_to_json(ws);
            this.setState({data: parsedData, cols: make_cols(ws['!ref']), isLoading: true}, () => {
                const customers = this.state.data;
                if (customers[0]['accountid']
                    && customers[0]['name'] !== null) {
                    this.updateCustomers(customers);
                    toast.success('Successfully Updated Customer List');
                } else {
                    toast.error('Not a valid file');
                    this.setState({isLoading: false});
                }
            });
        };
        if (readAsBinary) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        }
    }

    updateCustomers = (customerList) => {
        this.customers = customerList;
        try {
            this.customers.forEach((value, index) => {
                const customerId = value['accountid'];
                const customerName = value['name'];
                this.createCustomerApi({
                    customerId: customerId,
                    customerName: customerName
                }).then(response => {
                    this.setState({isLoading: false});
                }).catch(e => {
                    console.log(e);
                    this.setState({isLoading: false});
                })
            })
        } catch (error) {
            console.log(error);
            this.setState({isLoading: false});
        }

    }

    createCustomerApi = (customer) => {
        return API.post("license", "createCustomer", {
            body: customer
        })
    };

    render() {
        return (
            <div className={'Update Customers'}>
                <label htmlFor="file">Upload File to load Customer List</label>
                <br/>
                <input type="file" className="form-control" id="file" accept={SheetJSFT}
                       onChange={this.handleFileChange} data-cy={'file-input'}/>
                <br/>
                <LoaderButton
                    block
                    bsStyle="primary"
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type={"submit"}
                    isLoading={this.state.isLoading}
                    text={"Submit"}
                    loadingText={"Creating Customers..."}
                    data-cy={"submit"}
                    onClick={this.handleFileSubmit}
                />
                <ToastContainer position={toast.POSITION.TOP_RIGHT}/>

            </div>
        );
    }
}