const dev = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "license-generator-api-dev-attachmentsbucket-cxi5bris9g9p"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://d26g9kmn37.execute-api.eu-west-1.amazonaws.com/dev/"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_zxPCv1cQq",
        APP_CLIENT_ID: "11412bcptcvvsu12m07uqal1p9",
        IDENTITY_POOL_ID: "eu-west-1:add03b63-72f9-4f84-9858-21639598b293"
    }
};

const staging = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "license-generator-api-staging-attachmentsbucket-1upww26bbh3jw"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://2natso67uk.execute-api.eu-west-1.amazonaws.com/staging/"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_kLPm8iBQX",
        APP_CLIENT_ID: "1leevkd0jumocuo1ubocll0r2m",
        IDENTITY_POOL_ID: "eu-west-1:8ec883e8-a13e-419f-b75c-cf974626e651"
    }
};

const prod = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "license-generator-api-prod-attachmentsbucket-1ehdzll0ngotw"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://1c1rqn1m1f.execute-api.eu-west-1.amazonaws.com/prod/"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_VjjHhDMgI",
        APP_CLIENT_ID: "43ao4j8qo1ls0lcr57fk2ld9e6",
        IDENTITY_POOL_ID: "eu-west-1:209c0e47-77b9-4db7-b0d6-b6a00fbe7f7d"
    }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'production' ? prod
    : process.env.REACT_APP_STAGE === 'staging' ? staging
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};

