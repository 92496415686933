import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import AppliedRoute from './components/AppliedRoute';
import NewLicense from './containers/NewLicense';
import DownloadOrDeleteLicense from './containers/DownloadOrDeleteLicense';
import AuthenticatedRoutes from './components/AuthenticatedRoutes';
import UnauthenticatedRoutes from './components/UnauthenticatedRoutes';
import LicenseList from './containers/LicenseList';
import MultipleLicenses from './containers/MultipleLicenses';
import UpdateCustomers from './containers/UpdateCustomers';

export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <UnauthenticatedRoutes path="/login" exact component={Login} props={childProps}/>
        <AuthenticatedRoutes path="/license/multipleLicenses" exact component={MultipleLicenses} props={childProps} />
        <AuthenticatedRoutes path="/license/updateCustomers" exact component={UpdateCustomers} props={childProps} />
        <AuthenticatedRoutes path="/license/new" exact component={NewLicense} props={childProps} />
        <AuthenticatedRoutes path="/license/customer/new/:customerId" exact component={NewLicense} props={childProps} />
        <AuthenticatedRoutes path="/licenseList/:customerId" exact component={LicenseList} props={childProps} />
        <AuthenticatedRoutes path="/license/:licenseId" exact component={DownloadOrDeleteLicense} props={childProps} />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>;
