import React, {Component} from "react";
import {Button, PageHeader} from "react-bootstrap";
import "../styles/Home.css";
import {API} from "aws-amplify";
import ReactTable from 'react-table'
import 'react-table/react-table.css'

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            isLoading: true,
            customers: [],
            search: ''
        };
    }

    componentDidMount = async () => {
        if (!this.props.isAuthenticated) {
            return;
        }
        try {
            const customers = await this.customers();
            this.setState({customers});
        } catch (e) {
            alert(e);
        }
        this.setState({isLoading: false});
    };

    handleChange = (event) => {
        this.setState({search: event.value});
    }

    customers = () => {
        return API.get("license", `getCustomers`, null);
    }

    renderCustomerList = (customers) => {
        const columns = [{
            Header: 'Customer Name',
            accessor: 'customerName',
            Cell: props => <a href={`/licenseList/${props.original.customerId}`}>{props.original.customerName}</a>  // Custom cell components!
        },{
            Header: 'Type',
            sortable: false,
            filterable: false,
            Cell: <div className={'text-center'}>Customer</div> // Custom cell components!
        },{
            Header: 'Options', // Custom header components!
            accessor: 'customerId',
            sortable: false,
            filterable: false,
            Cell: props => <div className={'text-center'}><Button href={`/license/customer/new/${props.value}`} className={'btn btn-success '}>
                <i className="glyphicon glyphicon-plus"></i></Button></div>
        }]
        return (<ReactTable
                data={this.sortableCustomers(customers)}
                resolveData={customers => customers.map(row => row)}
                columns={columns}
                sortable={true}
                filterable={true}
                defaultFilterMethod={(filter, row) => this.filterCase(filter, row)}
                collapseOnSortingChange={true}
                loading={this.state.isLoading}
                className={'-highlight'}

            />
        )
    }
    sortableCustomers = (customers) => {
        return customers.sort((a, b) => a.customerName.localeCompare(b.customerName))
    }

    filterCase = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    renderCustomers = () => {
        return (
            <div className="licenses">
                <div className={"pull-right"}>
                    <Button href={"/license/new"} className={"btn btn-success"}><b>{"\uFF0B"}</b> Create a new
                        License</Button>
                    &nbsp;
                    <Button href={"/license/multipleLicenses"} className={"btn btn-default"}><b>{"\uFF0B"}</b> Create
                        Multiple Licenses</Button>
                    &nbsp;
                    <Button href={"/license/updateCustomers"} className={"btn btn-primary"}>Update
                        Customer List</Button>
                </div>
                <PageHeader>Customer List Corporater</PageHeader>
                {!this.state.isLoading && this.renderCustomerList(this.state.customers)}
            </div>
        );
    }
    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderCustomers() : this.props.history.push("/login")}
            </div>
        );
    }
}
