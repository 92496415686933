import React, {Component} from 'react';
import {API} from 'aws-amplify';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import '../styles/License.css';
import fileDownload from 'js-file-download';
import {NotificationManager} from 'react-notifications';

export default class DownloadOrDeleteLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleting: null,
            isDownloading: null,
            license: null,
            content: "",
        };
    }

    async componentDidMount() {
        try {
            const license = await this.getLicense();
            const {content} = license;
            this.setState({
                license,
                content,
            })
        } catch (e) {
            alert(e);
        }
    }

    getLicense = () => {
        const licenseId = this.props.match.params.licenseId;
        return API.get("license", `getLicense/${licenseId}`, null);
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    deleteLicense = () => {
        const licenseId = this.props.match.params.licenseId;
        return API.del("license", `deleteLicense/${licenseId}`, null);
    }

    handleDelete = async (event) => {
        event.preventDefault();
        const confirmed = window.confirm("Are you sure you want to delete this license?");
        if (!confirmed) {
            return;
        }
        this.setState({isDeleting: true});
        await this.deleteLicense();
        try {
            const timer = setTimeout( () => {
                NotificationManager.error('You have deleted License!', 'Successful!', 5000);
                this.setState({isDeleting: false});
            }, 1000);
            return () => clearTimeout(timer);
        } catch (e) {
            alert(e);
            this.setState({isDeleting: false});
        }
    }

    handleDownload = async (event) => {
        event.preventDefault();
        this.setState({isDownloading: true});
        try {
            const fileName = this.state.license.attachment + '.license';
            await fileDownload(this.state.content, fileName);
            const timer = setTimeout(() => {
                this.setState({isDownloading: false})
            }, 1000);
            return () => clearTimeout(timer);

        } catch (e) {
            alert(e)
            this.setState({isDownloading: false})
        }
    }
    render() {
        return (
            <div className={"DownloadOrDeleteLicense"}>
                {this.state.license &&
                <form>
                    <ControlLabel>License</ControlLabel>
                    <FormGroup controlId="content">
                        <FormControl
                            onChange={this.handleChange}
                            value={this.state.content}
                            componentClass="textarea"
                            readOnly={true}
                            rows={"30"}
                        />
                    </FormGroup>

                    <LoaderButton
                        block
                        bsStyle="success"
                        bsSize="large"
                        isLoading={this.state.isDownloading}
                        onClick={this.handleDownload}
                        text="Download"
                        loadingText="Downloading…"
                    />

                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                        data-cy={'delete'}
                    />
                </form>}
            </div>
        );
    }

}